<script setup>
	import helper from '~/resources/js/libraries/helper';
	import { useHomeStore } from '~/stores/home.store';

	const homeStore = useHomeStore();
	const data = ref([]);
	data.value = await homeStore.fetchTopTenListings();
	const currency = helper.cookies.currency();

</script>
<template>
	<section class=" bg-indigo-900" v-if="data?.length">
		<div class="container mx-auto my-10 py-10 max-w-[82rem] px-4 sm:px-6 lg:px-8">
			<div class="text-center mb-10 text-white text-2xl font-bold" v-html="$t('client.listings_top_ten')"></div>
			<w-carousel-multi :isHaveSlot="true"  :items="data" :imagesToShow="4">
			<template #item="{item,index}">
				<div class="grid grid-cols-1 mx-3 w-72 overflow-hidden" itemscope itemtype="https://schema.org/RealEstateListing">
					<NuxtLink :to="localePath({ name: 'listings.show', params: { slug: item.slug } })" itemprop="url">
						<div class="relative">
							<img loading="lazy" class="w-full h-56 object-cover rounded-tl-2xl rounded-tr-2xl"
								:src="`${item.picture}?w=299&h=224`" :alt="item.title" itemprop="thumbnailUrl" />
							<div class="left-0 bottom-0 absolute text-center text-9xl font-bold"
								style="color: white;-webkit-text-fill-color: transparent;-webkit-text-stroke: 2px white;font-size: 240px;line-height: 0.7;">
								{{ index + 1 }}
							</div>
						</div>
						<div
							class="px-12 py-2.5 bg-white rounded-bl-2xl rounded-br-2xl flex-col items-center gap-2.5 flex relative">
							<div class="text-center text-neutral-400 text-xs font-normal leading-none truncate">{{ item.cities.join(' / ') }}</div>
							<div class="text-center text-indigo-900 text-xl font-bold leading-normal">
								{{ helper.formatPrice(item.sale_price) }}
								{{ currency.symbol }}
							</div>
						</div>
					</NuxtLink>
				</div>
			</template>
			</w-carousel-multi>
		</div>
	</section>
</template>
